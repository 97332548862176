import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

  const TestimonialsContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 5em 0;
    scroll-margin-top: 160px;

    @media (max-width: 430px) {
      margin: 2em 0; /* Adjust margin for smaller screens */
    }
`;

const SliderContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #200080; */
  z-index:888;

  @media (max-width: 600px) {
    margin: 0 auto;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px; /* Max width of the container */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

    @media (max-width: 600px) {
      justify-content: flex-start; /* Adjust for smaller screens */
    }
`;


const ArrowButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0px 20px;

    @media (max-width: 430px) {
      padding: 10px 0px;
      margin: 0px 10px;
    }
`;

const ArrowImg = styled.img`
  width: 20px;
  height: 30px;
`;

const ImgTitle = styled.img`
  width: 70%;
  max-width: 500px;
  height: auto;
  margin-bottom: 60px;
  z-index: 3;

  @media (max-width: 430px) {
    width: 60%;
  }
`;

const TestimonialsSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #400b40; */
`;

const TestimonialBlock = styled.div`
  border: 20px solid transparent;
  border-image: url('/img/border.png') 30 stretch;
  width: 380px;
  height: 440px;
  text-align: left;
  color: white;
  margin: 0 20px; 
  position: relative;
  background: transparent;
  /* background: blue; */

  @media (max-width: 1385px) {
    width: 300px;
    height: 480px;
  }

  @media (max-width: 430px) {
    width: 250px;
    margin: 0 10px; 
  }
`;

const BrandItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
  position: absolute;
  z-index: 2;
`;

const BrandItemTitle = styled.a`
  cursor:pointer;
  margin-left:20px;
  height:25%;
  position:relative;

  @media (max-width: 1385px) {
      margin-left:0px;
  }

  @media (max-width: 877px) {
    height:18%;
  }
`;

const BrandItemText = styled.p`
  max-width: 300px;
  font-size: 18px;
  color: #555;
  white-space: pre-line;
  text-align: left;
  color: white;
  margin: 0 auto;
  z-index: 2;

  @media (max-width: 430px) {
    font-size: 16px;
  }
`;

const BrandItemButton = styled.button`
  background-color: #B98844;
  border: 1px solid #B98844;
  color: black;
  width: auto;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 20px;

  &:hover {
    background-color: transparent;
    color: white;
  }

  @media (max-width: 1385px) {
      left:0px;
  }

  @media (max-width: 430px) {
    padding: 5px 10px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  width: 700px;
  height: auto;
  right: 5em;
  top: 50%;
  transform: rotate(5deg); /* Rotate the image to the left */
  z-index: 1;

  @media (max-width: 1700px) {
    right: -1em;
    top: 50%;
    z-index: 1;
  }

  @media (max-width: 1500px) {
    right: -6em;
    top: 50%;
    z-index: 1;
  }

  @media (max-width: 1379px) {
    right: -20em;
    top: 30%;
    z-index: 1;
    transform: rotate(-15deg);
  }

  @media (max-width: 430px) {
    position: absolute;
    width: 1000px;
    height: auto;
    right: -45em;
    top: 60%;
    transform: translateY(-50%) rotate(-15deg); /* Rotate the image to the left */
    z-index: 1;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  margin-top: 5em;

  @media (max-width: 876px) {
    margin-top: 2em;
  }
`;

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numVisible, setNumVisible] = useState(getNumVisibleBlocks());

  useEffect(() => {
    const handleResize = () => {
      setNumVisible(getNumVisibleBlocks());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function getNumVisibleBlocks() {
    if (window.innerWidth <= 877) {
      return 1;
    } else if (window.innerWidth <= 1180) {
      return 2;
    } else {
      return 3;
    }
  }

  const content = [
    {
      href:'https://zeuswin.com/',
      src: '/img/logos/zeuswinLogo.svg',
      answer: 'Step into the ultimate gaming paradise where casino excitement meets the thrill of sports betting! Our website is your gateway to a world of endless entertainment,offering a wide array of casino games and sportsbook options.',
      span: ' Get ready to experience the pulse-pounding action ',
      end:'and the chance to win big. Join us today and let the games begin!',
      a:'https://ro-affiliate.clubaffiliates.com/registration',
      button:'REGISTER',
    },
    {
      href:'https://placebet.io/',
      src: '/img/logos/place.svg',
      answer: 'Get ready to elevate your gaming experience to new heights! Welcome to the ultimate destination where the adrenaline rush of casino games meets the excitement of sports betting.',
      span: ' At Placebet, we offer a thrilling fusion of top-tier casino entertainment and dynamic sportsbook options, all in one convenient location. ',
      end:'Join us today and immerse yourself in a world of endless possibilities!',
      a:'https://login.clubaffiliates.com/signup.php',
      button:'REGISTER',
    },
    {
      href:'https://betfunk.com/',
      src: '/img/logos/bet.svg',
      answer: 'Welcome to the premier destination where the thrill of the',
      span: ' casino meets the excitement of sports betting! ',
      end:'At our website, you’ll discover a dynamic fusion of casino games and sportsbook options, designed to deliver unparalleled entertainment and opportunities to win big. Join us now and embark on an exhilarating journey in the world of gaming and sports wagering!',
      a:'https://login.clubaffiliates.com/signup.php',
      button:'REGISTER',
    },
  ];

  const showPrevTestimonials = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - numVisible));
  };

  const showNextTestimonials = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + numVisible, content.length - numVisible));
  };

  const visibleTestimonials = content.slice(currentIndex, currentIndex + numVisible);

  
  const titleRef = useRef(null);

  useEffect(() => {
    const title = titleRef.current;


        gsap.fromTo(
        title, 
        { autoAlpha: 0 ,y:100 }, // start state: invisible
        { 
          autoAlpha: 1, // end state: fully visible
          duration: 1, // duration of the animation
          y:0,
          scrollTrigger: {
            trigger: title, // element that triggers the animation
            start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
          }
        }
      );

      gsap.fromTo(
        '.block',
        { autoAlpha: 0  }, // start state: invisible
        { 
          autoAlpha: 1, // end state: fully visible
          duration: 1, 
          stagger: {
          each: 0.5, // Adjust this value to change the delay between each testimonial block
        },
          scrollTrigger: {
            trigger: '.block', // element that triggers the animation
            start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
          }
        }
      );

    }, []);


  return (
    <TestimonialsContainer id="brands">
        <BackgroundImage src="/img/brands.png" alt="Background Image" />
      <ImgTitle src="/img/brand.png" alt="Brand Title" ref={titleRef}/>

      <Container>
        <SliderContainer>

          <TestimonialsSlider>
          <ArrowButton onClick={showPrevTestimonials}>
            <ArrowImg src="/img/Aleft.png" alt="Left Arrow" />
          </ArrowButton>
          
          {visibleTestimonials.map((item, index) => (
              <TestimonialBlock className="block">
              <BrandItem>
              <BrandItemTitle href={item.href} target="_blank"><img style={{ width :'50%'}}  src={item.src} alt="logo" /></BrandItemTitle>
              
              {/* style={{ marginTop: item.src === '/img/placebet.svg' ? '-8px' : '0' }} */}
             <BrandItemText>
              {item.answer}
                <span style={{ color: '#B98844', fontWeight: 'bold' }}>
                {item.span}
                </span> 
                {item.end}
              </BrandItemText>
              <a href={item.a} target="_blank">
                <BrandItemButton>{item.button}</BrandItemButton>
              </a>
            </BrandItem>
            </TestimonialBlock>
          ))}

            <ArrowButton onClick={showNextTestimonials}>
            <ArrowImg src="/img/Aright.png" alt="Right Arrow" />
          </ArrowButton>

          </TestimonialsSlider>
        </SliderContainer>
      </Container>
    </TestimonialsContainer>
  );
};

export default Testimonials;
